<template>
  <div class="emailConfirm">
    <div class="centerContent">
      <section class="formContainer">
        <!-- confirmation success-->
        <div v-if="status === 1 || status === 2">
          <h2>Verification Complete</h2>
          <p>
            Congratulations! Your account has been successfully confirmed.
            Please
            <router-link :to="{ name: 'login' }" class="blueTextLink"
              >login</router-link
            >
            to access your Hotspex Labs Dashboard.
          </p>
        </div>
        <!-- confirmation fail -->
        <div v-if="status === 3 || status === 0">
          <h2>Oops...</h2>
          <p>
            We've run into a problem processing your request. Please try again
            or contact support at
            <a href="mailto:support@hotspexstudio.com" target="_blank"
              >support@hotspexstudio.com</a
            >.
          </p>
        </div>
        <!-- in confirmation -->
        <div v-if="status === -1">
          <p>Verification in process ...</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// https://app-hsxstudio-staging.azurewebsites.net/app/email-confirm?eid=8eHt4xBb5BWOiTZ53pCygT__LgZnTDW7Bp8__yOdALv9UaUEQBJQ9ZRYiSCBsu9r3g
import * as wsUtils from "@/utils/wsUtils.js";
export default {
  data() {
    return {
      status: -1,
    };
  },
  created() {
    const params = this.$route.query;

    const verifyPromise = new Promise((resolve) => {
      wsUtils.VerifyEmail(resolve, { VerifyId: params.eid });
    });

    verifyPromise.then((data) => {
      this.status = data.Status;
    });
  },
};
</script>

<style lang="scss" scoped>
.emailConfirm {
  background: url("../../public/images/logBG5.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  background-position: center;
  height: 100%;
  background-attachment: fixed;

  display: grid;
}

.formContainer {
  padding: 2em;
  text-align: center;
  line-height: 2em;
}
</style>
